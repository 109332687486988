
import { defineComponent, onMounted, ref } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ToastService from "@/core/services/ToastService";
import { displayErrors } from "@/core/helpers/errors";
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {ListAttributes} from "@/core/model/ListAttriibutes";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

interface ItemSelect {
  value: string;
  label: string;
}

export default defineComponent({
  name: "users-form",
  emit: ["update-success"],
  components: {},
  props: {
    //mutual: { type: Object, default: {} as Mutual }
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const loadingMutual = ref<boolean>(false);
    const loadingProductLines = ref<boolean>(false);
    const loadingDocTypes = ref<boolean>(false);
    const optionsRol = ref<Array<ItemSelect>>([
      {
        value: "ROLE_ADMIN_MUTUAL",
        label: "Administrador Mutual",
      },
      {
        value: "ROLE_USER_MUTUAL",
        label: "Empleado",
      },
    ]);
    const optionsMutual = ref<ItemSelect[]>([])
    const formData = ref({
      mutual: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      roles: "",
      status: false,
      username: "",
      password: "",
      confirm_password: "",
      user: "",
    });

    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Por favor ingrese la contraseña de nuevo"));
      } else if (value !== formData.value.password) {
        callback(new Error("Las contraseñas no coinciden"));
      } else {
        callback();
      }
    };

    const validateCustomPassRequired = (rule, value, callback) => {
      if (!isEditPage() && value === "") {
        callback(new Error("Este campo es obligatorio"));
      } else {
        callback();
      }
    };

    const rules = ref({
      firstname: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 60,
          message: "Este campo admite máximo 60 caracteres",
          trigger: "change",
        },
      ],
      lastname: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 60,
          message: "Este campo admite máximo 60 caracteres",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 255,
          message: "Este campo admite máximo 255 caracteres",
          trigger: "change",
        },
      ],
      phone: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 30,
          message: "Este campo admite máximo 30 caracteres",
          trigger: "change",
        },
      ],
      roles: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 30,
          message: "Este campo admite máximo 30 caracteres",
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ],
      username: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          max: 50,
          message: "Este campo admite máximo 50 caracteres",
          trigger: "change",
        },
      ],
      password: [
        {
          //required: true,
          //message: "Este campo es obligatorio",
          validator: validateCustomPassRequired,
          trigger: "blur",
        },
        {
          min: 8,
          max: 50,
          message: "La contraseña debe estar entre 8 y 50 caracteres",
          trigger: "blur",
        },
      ],
      confirm_password: [
        {
          //required: true,
          //message: "Este campo es obligatorio",
          validator: validateCustomPassRequired,
          trigger: "blur",
        },
        {
          validator: validatePass2,
          trigger: "blur",
        },
      ],
    });

    if(store.getters.isAdmin) {
      rules.value['mutual'] = [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
      ];
    }

    onMounted(() =>
    {
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Usuarios", [
        "Usuarios",
        isEditPage() ? "Editar" : "Nuevo",
      ]);
      if (isEditPage()) {
        loadUser();
      }
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (isEditPage()) {
            formData.value.user = String(route.params.uuid);
          }
          store
            .dispatch(
              isEditPage() ? Actions.USERS_UPDATE : Actions.USERS_CREATE,
              formData.value
            )
            .then(() => {
              loading.value = false;
              if (store.state.UsersModule.success) {
                emit("update-success");
                ToastService.show(
                  "Usuario " +
                    (isEditPage() ? "actualizado" : "creado") +
                    " con éxito",
                  "success"
                );
                router.push({
                  name: store.getters.isAdmin ? "admin_users" : "mutual_users",
                  //params: { uuid: store.state.ProfilingModule.request.uuid },
                });
              }
            })
            .catch(() => {
              displayErrors(store, "UsersModule");
              loading.value = false;
            });
        } else {
          ToastService.show(
            "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            "error"
          );
          return false;
        }
      });
    };

    const loadUser = () => {
      loading.value = true;
      store
        .dispatch(Actions.USERS_GET, route.params.uuid)
        .then(() => {
          loading.value = false;
          formData.value = store.state.UsersModule.user;
          if(store.getters.isAdmin && isEditPage() && typeof store.state.UsersModule.user.mutual.uuid !== 'undefined'){
            optionsMutual.value.push({
              value: store.state.UsersModule.user.mutual.uuid,
              label: store.state.UsersModule.user.mutual.name
            } as ItemSelect);
            formData.value.mutual = store.state.UsersModule.user.mutual.uuid;
          }
        })
        .catch(() => {
          displayErrors(store, "UsersModule");
          loading.value = false;
        });
    };

    const isEditPage = () => {
      return typeof route.params.uuid !== "undefined";
    };

    const searchMutual = (query: string) => {
      if (query) {
        loadingMutual.value = true

        const params = {
          page_number: 1,
          page_size: 100,
          sort_order: 'ASC',
          sort_field: 'name',
          search: query,
        } as ListAttributes;
        store
          .dispatch(Actions.ADMIN_MUTUAL_LIST, params)
          .then(() => {
            optionsMutual.value = []
            for(let i=0; i< store.state.AdminMutualesModule.list.items.length; i++){
              optionsMutual.value.push({
                value: store.state.AdminMutualesModule.list.items[i].uuid,
                label: store.state.AdminMutualesModule.list.items[i].name
              } as ItemSelect);
            }
            loadingMutual.value = false;

          })
          .catch(() => {
            displayErrors(store, "AdminMutualesModule");
            loadingMutual.value = false;
          });
      } else {
        optionsMutual.value = []
      }
    }

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      loadingMutual,
      loadingProductLines,
      loadingDocTypes,
      isEditPage,
      optionsRol,
      store,
      optionsMutual,
      searchMutual,
    };
  },
});
